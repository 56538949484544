export const API_ABSENCE_REASONS = '/api/2.0/absence-reasons'
export const API_ACCREDITATIONS = '/api/2.0/accreditations'
export const API_ACCREDITATION_APPLICATIONS = '/api/2.0/accreditation-applications'
export const API_ACCREDITATION_DOMAINS = '/api/2.0/accreditation-domains'
export const API_ACCREDITATION_PERIMETERS = '/api/2.0/accreditation-perimeters'
export const API_ACCREDITATION_PREREQUISITES = '/api/2.0/accreditation-prerequisites'
export const API_ACCREDITATION_SIGNINGS = '/api/2.0/accreditation-signings'
export const API_ACCREDITATION_TRAININGS = '/api/2.0/accreditation-trainings'
export const API_ACCREDITATION_TRIGGER_EMAILS = '/api/2.0/trigger-email-accreditation'
export const API_ADMIN_ASSIGNMENTS = '/api/2.0/admin/profiles/:id/assignments'
export const API_ADMIN_ABSENCE_REASONS = '/api/2.0/admin/absence-reasons'
export const API_ADMIN_ACCREDITATIONS = '/api/2.0/admin/accreditations'
export const API_ADMIN_ACCREDITATION_APPLICATIONS = '/api/2.0/admin/accreditation-applications'
export const API_ADMIN_ACCREDITATION_CHOICES = '/api/2.0/admin/accreditation-choices'
export const API_ADMIN_ACCREDITATION_DOMAINS = '/api/2.0/admin/accreditation-domains'
export const API_ADMIN_ACCREDITATION_PERIMETERS = '/api/2.0/admin/accreditation-perimeters'
export const API_ADMIN_ACCREDITATION_PREREQUISITES = '/api/2.0/admin/accreditation-prerequisites'
export const API_ADMIN_ACCREDITATION_SIGNINGS = '/api/2.0/admin/accreditation-signings'
export const API_ADMIN_CERTIFICATES = '/api/2.0/admin/certificates'
export const API_ADMIN_COLLABORATORS = '/api/2.0/admin/collaborators'
export const API_ADMIN_CONTRACT_TYPES = '/api/2.0/admin/contract-types'
export const API_ADMIN_CRITERIONS_ACCREDITATION = '/api/2.0/admin/criterions-accreditation'
export const API_ADMIN_CRITERIONS_CUSTOM = '/api/2.0/admin/criterions-custom'
export const API_ADMIN_CRITERIONS_GROUP = '/api/2.0/admin/criterions-group'
export const API_ADMIN_CRITERIONS_TRAINING = '/api/2.0/admin/criterions-training'
export const API_ADMIN_CRITERION_CHOICES = '/api/2.0/admin/criterion-choices'
export const API_ADMIN_CSPS = '/api/2.0/admin/socio-professional-categories'
export const API_ADMIN_CUSTOM_FIELDS = '/api/2.0/admin/custom-fields'
export const API_ADMIN_DOCUMENTS_ARCHIVE = '/api/2.0/documents/create-archive'
export const API_ADMIN_DOC_TEMPLATES = '/api/2.0/admin/doc-templates'
export const API_ADMIN_DOC_TEMPLATES_DOCUMENTS = '/api/2.0/admin/doc-templates/:id/documents'
export const API_ADMIN_LOCATIONS = '/api/2.0/admin/locations'
export const API_ADMIN_ORGANIZATIONS = '/api/2.0/admin/organizations'
export const API_ADMIN_ORIENTATIONS = '/api/2.0/admin/orientations'
export const API_ADMIN_PERMISSIONS = '/api/2.0/admin/permissions'
export const API_ADMIN_POPULATION = '/api/2.0/admin/populations'
export const API_ADMIN_PRIORITIES = '/api/2.0/admin/priorities'
export const API_ADMIN_PROFILES = '/api/2.0/admin/profiles'
export const API_ADMIN_SUB_PROFILES = '/api/2.0/admin/sub-profiles'
export const API_ADMIN_TASKS = '/api/2.0/admin/tasks'
export const API_ADMIN_TASK_GROUPS = '/api/2.0/admin/task-groups'
export const API_ADMIN_TASK_GROUPS_DUPLICATE = '/api/2.0/admin/task-groups/:id/duplicate'
export const API_ADMIN_TIMEZONES = '/api/2.0/admin/timezones'
export const API_ADMIN_TRAINING_PERIMETERS = '/api/2.0/admin/training-perimeters'
export const API_ADMIN_TRIGGER_EMAILS = '/api/2.0/admin/trigger-emails'
export const API_ADMIN_TRIGGER_EMAIL_DEFINITIONS = '/api/2.0/admin/trigger-email-definitions'
export const API_ADMIN_TRIGGER_EMAIL_SURVEYS = '/api/2.0/admin/trigger-email-surveys'
export const API_ADMIN_TRIGGER_MAIL_USERS = '/api/2.0/admin/trigger-mail-users'
export const API_ADMIN_USERS = '/api/2.0/admin/users'
export const API_ADMIN_VINTAGES = '/api/2.0/admin/vintages'
export const API_BOOMERANG_ACCOUNTING_ENTRIES = '/api/2.0/boomerang/accounting-entries'
export const API_BOOMERANG_CURRENCIES = '/api/2.0/boomerang/currencies'
export const API_BOOMERANG_PARAMETERS = '/api/2.0/boomerang/parameters'
export const API_BOOMERANG_PAYMENT_TERMS = '/api/2.0/boomerang/payment-terms'
export const API_BOOMERANG_PAYMENT_TYPES = '/api/2.0/boomerang/payment-types'
export const API_BOOMERANG_PROJECTS = '/api/2.0/boomerang/projects'
export const API_BOOMERANG_SUPPLIERS = '/api/2.0/boomerang/suppliers'
export const API_BOOMERANG_SUPPLIER_CONTACTS = '/api/2.0/boomerang/supplier-contacts'
export const API_CATALOG_ACCREDITATIONS = '/api/2.0/catalog/accreditations'
export const API_CERTIFICATES = '/api/2.0/certificates'
// eslint-disable-next-line max-len
export const API_CERTIFICATE_CRITERION_WISHES_CUSTOM_NEIGHBORS = '/api/2.0/certificate-criterion-wishes/:id/custom-neighbors'
export const API_CERTIFICATE_CRITERION_WISHES_INVALIDATE = '/api/2.0/certificate-criterion-wishes/:id/invalidate'
export const API_CERTIFICATE_CRITERION_WISHES_TITLE = '/api/2.0/certificate-criterion-wishes/:id/title'
// eslint-disable-next-line max-len
export const API_CERTIFICATE_CRITERION_WISHES_VALIDATION_DATE = '/api/2.0/certificate-criterion-wishes/:id/validation-date'
export const API_CERTIFICATE_TRIGGER_EMAILS = '/api/2.0/trigger-email-certificate'
export const API_CERTIFICATE_WISHES = '/api/2.0/certificate-wishes'
export const API_CERTIFICATE_WISHES_ACTIONS = '/api/2.0/certificate-wishes/:id/actions'
export const API_CERTIFICATE_WISHES_TITLE = '/api/2.0/certificate-wishes/:id/title'
export const API_CERTIFICATE_WISHES_COUNT = '/api/2.0/certificate-wishes/count'
export const API_CERTIFICATE_WISHES_DOCUMENTS = '/api/2.0/certificate-wishes/:id/documents'
export const API_COLLECTIVES = '/api/2.0/collectives'
export const API_CUSTOM_FIELDS = '/api/2.0/custom-fields'
export const API_DOCUMENT = '/api/2.0/documents/:id'
export const API_DOCUMENTS = '/api/2.0/documents'
export const API_DOCUMENTS_WISH = '/api/2.0/documents/wishes'
export const API_DOC_TEMPLATES = '/api/2.0/doc-templates'
export const API_EMAIL_TEMPLATES = '/api/2.0/email-templates'
export const API_ESTABLISHMENTS = '/api/2.0/establishments'
export const API_EXPORTS = '/api/2.0/exports'
export const API_EXPORT_TASKS = '/api/2.0/export-tasks'
export const API_FILTERS = '/api/2.0/filters'
export const API_INITIALS = '/api/2.0/initials'
export const API_LOCATIONS = '/api/2.0/locations'
export const API_NOTIFICATIONS = '/api/2.0/notifications'
export const API_NOTIFICATIONS_MARK_ALL_AS_READ = '/api/2.0/notifications/read'
export const API_NOTIFICATIONS_MARK_AS_READ = '/api/2.0/notifications/:id/read'
export const API_NOTIFICATIONS_MARK_AS_UNREAD = '/api/2.0/notifications/:id/unread'
export const API_NOTIFICATIONS_COUNT = '/api/2.0/notifications/count'
export const API_ORGANIZATIONS = '/api/2.0/organizations'
export const API_ORGANIZATION_TYPES = '/api/2.0/organization-types'
export const API_ORIENTATIONS = '/api/2.0/orientations'
export const API_PERMISSIONS = '/api/2.0/permissions'
export const API_POPULATION = '/api/2.0/populations'
export const API_PRIORITIES = '/api/2.0/priorities'
export const API_PROFILES = '/api/2.0/profiles'
export const API_PROJECTS = '/api/2.0/projects'
export const API_REMINDERS = '/api/2.0/reminders'
export const API_REMINDERS_COUNT = '/api/2.0/reminders/notifications'
export const API_REMINDERS_FREE = '/api/2.0/reminders/free'
export const API_REMINDERS_REASSIGN = '/api/2.0/reminders/reassign'
export const API_REMINDERS_SESSIONS = '/api/2.0/reminders/sessions'
export const API_REMINDERS_SESSIONS_FREE = '/api/2.0/reminders/sessions/free'
export const API_REMINDERS_SESSIONS_REASSIGN = '/api/2.0/reminders/sessions/reassign'
export const API_REMINDERS_SESSIONS_RECIPIENTS = '/api/2.0/reminders/sessions/recipients'
export const API_REMINDER_COMMENT = '/api/2.0/reminders/:id/comment'
export const API_REMINDER_DONE = '/api/2.0/reminders/:id/done'
export const API_SESSIONS = '/api/2.0/sessions'
export const API_SESSIONS_ACTIONS = '/api/2.0/sessions/:id/actions'
export const API_SESSIONS_REMINDERS_DUE_DATE = '/api/2.0/sessions/:session_id/reminders/:reminder_id/due-date'
export const API_SESSIONS_UNCANCEL = '/api/2.0/sessions/:id/uncancel'
export const API_SESSION_CANCEL = '/api/2.0/sessions/:id/cancel'
export const API_SESSION_EMAIL_SETTINGS = '/api/2.0/sessions/:id/email-settings'
export const API_SESSION_ORDERS = '/api/2.0/session-orders'
export const API_SESSION_ORDERS_CANCEL = '/api/2.0/session-orders/:id/cancel'
export const API_SESSION_PRIVACY = '/api/2.0/sessions/:id/privacy'
export const API_SESSION_REMINDERS = '/api/2.0/sessions/:id/reminders'
export const API_SESSION_SEND_EVENT = '/api/2.0/sessions/:id/calendar/event'
export const API_SESSION_TASK_GROUP = '/api/2.0/sessions/:id/task-group'
export const API_SETTINGS = '/api/2.0/settings'
export const API_SITES = '/api/2.0/sites'
export const API_SUB_PROFILES = '/api/2.0/sub-profiles'
export const API_TASKS = '/api/2.0/tasks'
export const API_TASK_GROUPS = '/api/2.0/task-groups'
export const API_TIMEZONES = '/api/2.0/timezones'
export const API_TRAININGS = '/api/2.0/trainings'
export const API_TRAININGS_MANAGEMENT = '/api/2.0/training-management/trainings'
export const API_TRAINING_BUDGETS = '/api/2.0/training-budgets'
export const API_TRAINING_CATEGORIES = '/api/2.0/training-categories'
export const API_TRAINING_CHOICES = '/api/2.0/training-choices'
export const API_TRAINING_CHOICES_COUNT = '/api/2.0/training-choices/count'
export const API_TRAINING_PERIMETERS = '/api/2.0/training-perimeters'
export const API_TRAINING_PLANS = '/api/2.0/training-plans'
export const API_TRAINING_THEMES = '/api/2.0/training-themes'
export const API_TRAINING_WISHES = '/api/2.0/training-wishes'
export const API_TRAINING_SNAPSHOT = '/api/2.0/training-wish-snapshots'
export const API_TRAINING_WISHES_ACTIONS = '/api/2.0/training-wishes/:id/actions'
export const API_TRAINING_WISHES_CHARTS = '/api/2.0/training-wishes/charts'
export const API_TRAINING_WISHES_OPENING_EMAIL = '/api/2.0/training-wishes/opening-email'
export const API_TRAINING_WISHES_SEND_OPENING_EMAIL = '/api/2.0/training-wishes/send-opening-email'
export const API_TRAINING_WISH_VALIDATE = '/api/2.0/training-wishes/validate'
export const API_TRAINING_WISH_VALIDATE_ALL = '/api/2.0/training-wishes/validate/all'
export const API_TRAINING_WISH_PERIMETERS = '/api/2.0/training-wish-perimeters'
export const API_TRAINING_WISH_REASONS = '/api/2.0/training-wish-reasons'
export const API_TRAINING_WISH_STATES = '/api/2.0/training-wish-states'
export const API_TRAINING_WISH_STATES_NEEDS_SUMMARY = '/api/2.0/training-wish-states/needs-summary'
export const API_TRAINING_WISH_STATES_TRAINING_PLAN = '/api/2.0/training-wish-states/training-plan'
export const API_TRAINING_WISH_TYPES = '/api/2.0/training-wish-types'
export const API_TRIGGER_EMAILS = '/api/2.0/trigger-emails'
export const API_ADMIN_TRIGGER_EMAIL_SURVEYS_DELETE = '/api/2.0/admin/trigger-email-surveys'
export const API_TRIGGER_EMAILS_EMAIL_TEMPLATES = '/api/2.0/trigger-emails/:id/email-templates'
export const API_TRIGGER_EMAILS_USERS = '/api/2.0/trigger-mail-users'
export const API_TRIGGER_EMAILS_SURVEYS_EMAIL_TEMPLATES = '/api/2.0/admin/trigger-email-surveys/:id/email-templates'
export const API_TRIGGER_EMAILS_STATE = '/api/2.0/trigger-emails/:id/state'
export const API_TRIGGER_EMAIL_ACCREDITATION = '/api/2.0/trigger-email-accreditation'
export const API_TRIGGER_EMAIL_DEFINITIONS = '/api/2.0/trigger-email-definitions'
export const API_USER = '/api/2.0/user'
export const API_USERS = '/api/2.0/users'
export const API_USERS_REMINDER_RECIPIENTS = '/api/2.0/users/reminder-recipients'
export const API_VINTAGES = '/api/2.0/vintages'
